import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-blog.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`PCMH Restore Health team headed to Thomson Reuters to give a talk on various health issues faced by the corporate employees. Importance of adult vaccination, sexual health and mental health are some of the topics covered in this engaging talk. This was followed by a Q & A session where the employees asked some insightful, challenging questions on health care which our team addressed, discussed and shed light upon.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/436fbd5340aa408464fa11e231d0834f/ca4a8/corporate-wellness-talk-at-thomson-reuters-1.webp",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRoAAAABXRUJQVlA4IHQAAACwAwCdASoUAAwAPtFUo0uoJKMhsAgBABoJZwDCgB7HekQjELuT8AD9TrOMZ3uoY9yvvpxfFzvjB9+udPjOh6OBfe5zrff/uw7OWapa6Slg+MFYCOXoE6cQ2hzp3NeH4/R2n07MzSCnS7cDhYmpK8DILvAAAA==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image of team at Thomson Reuters",
            "title": "Image of team at Thomson Reuters",
            "src": "/static/436fbd5340aa408464fa11e231d0834f/ca4a8/corporate-wellness-talk-at-thomson-reuters-1.webp",
            "srcSet": ["/static/436fbd5340aa408464fa11e231d0834f/c85cb/corporate-wellness-talk-at-thomson-reuters-1.webp 300w", "/static/436fbd5340aa408464fa11e231d0834f/e88ff/corporate-wellness-talk-at-thomson-reuters-1.webp 600w", "/static/436fbd5340aa408464fa11e231d0834f/ca4a8/corporate-wellness-talk-at-thomson-reuters-1.webp 740w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5d11547466d8efc17e215252eb2a8856/ca4a8/corporate-wellness-talk-at-thomson-reuters-2.webp",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "82.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRqwAAABXRUJQVlA4IKAAAAAwBACdASoUABAAPtFUo0uoJKMhsAgBABoJZQCw7BuerzPojXUnD29RyoAA/LG08qJWYqDRBVwWPwXDcsiCrennJoGbe809kSXpVwb63rownZCeuSmsF9ubtGZKMOVyagXR6P5in0yy5woKZHz7SQsIMZ0gvl3IDoEQ6tnlBpO3VhPB3z4MPuhe60tt3mwx0N7Ktfyx4Ni5kFQP9K3FQAAA')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image of team at Thomson Reuters",
            "title": "Image of team at Thomson Reuters",
            "src": "/static/5d11547466d8efc17e215252eb2a8856/ca4a8/corporate-wellness-talk-at-thomson-reuters-2.webp",
            "srcSet": ["/static/5d11547466d8efc17e215252eb2a8856/c85cb/corporate-wellness-talk-at-thomson-reuters-2.webp 300w", "/static/5d11547466d8efc17e215252eb2a8856/e88ff/corporate-wellness-talk-at-thomson-reuters-2.webp 600w", "/static/5d11547466d8efc17e215252eb2a8856/ca4a8/corporate-wellness-talk-at-thomson-reuters-2.webp 740w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      